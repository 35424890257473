<template>
	<article class="view-validate bg-blue-gradient">
		<div class="site-wrapper rythm-v">
			<header class="page-header">
				<h1>Confirmation de votre compte</h1>
			</header>

			<div class="rythm-v flex-row flex-center-v flex-center-h">
				<div class="form-wrapper card shadow" v-if="validationPending">
					<h2>Validation en cours…</h2>
					<p>Veuillez patienter pendant la validation de votre compte.</p>
				</div>

				<div class="form-wrapper card shadow" v-if="validationSuccess === false">
					<h2>Ce lien d'activation a expiré ou n'est pas valide.</h2>

					<p>Demandez l'envoi d'un nouvel e-mail de confirmation :</p>

					<form @submit.prevent="sendActivationEmail">
						<div class="input-group">
							<label for="email">Votre adresse e-mail :</label>
							<input type="email" name="email" id="email" v-model="email" required />
						</div>

						<div class="input-group--controls">
							<button type="submit" class="button--primary">Renvoyer un lien d'activation</button>
						</div>
					</form>
				</div>

				<div class="form-wrapper card shadow" v-if="validationSuccess === true">
					<h2>Votre compte est validé, merci !</h2>
					<p>Veuillez vous connecter pour terminer l'inscription.</p>
					<p class="text-center">
						<router-link to="/login" class="button--cta">Connexion</router-link>
					</p>
				</div>
			</div>
		</div>
	</article>
</template>

<style lang="scss" scoped>
.bg-blue-gradient {
	background-size: 100% 16rem;
}

.page-header h1 {
	color: white;
	text-align: center;
}

.card {
	flex-basis: 50rem;
	text-align: center;
}

form {
	text-align: left;
	margin-top: 2rem;
}
</style>

<script>
import {HTTP} from "../http-common";

export default {
	name: "UserValidation",

	data: function() {
		return {
			userID: null,
			validationToken: null,
			validationPending: true,
			validationSuccess: null,
			email: null,
		};
	},

	methods: {
		sendActivationEmail: function(e) {
			let userID = this.userID;
			let email = this.email;

			this.$store
				.dispatch("NEW_VALIDATION_EMAIL", email)
				.then(() => {
					this.$toasted.global.appSuccess({
						message: "Consultez votre boîte de réception, un lien d'activation vous a été envoyé.",
					});
				})
				.catch((error) => {
					// Either the user and email doesn't match or the user is inactive or nonexistent
					if (error.response.status === 403) {
						this.$toasted.global.appError({
							message: "Cette adresse ne correspond pas à un utilisateur CrossDoc.",
						});
					}
					// Email is invalid
					else if (error.response.status === 422) {
						this.$toasted.global.appError({
							message: "Une erreur est survenue, vérifiez votre adresse e-mail.",
						});
					}
					// Cannot send email, server error, …
					else {
						this.$toasted.global.appError({
							message: "Une erreur est survenue lors de l'envoi de l'email d'activation, veuillez nous contacter.",
						});
					}
				});
		},

		checkValidationToken: function(userID, validationToken) {
			this.$store
				.dispatch("USER_VALIDATE", {userID, validationToken})
				.then(() => {
					this.validationPending = false;
					this.validationSuccess = true;
				})
				.catch((error) => {
					this.validationPending = false;
					this.validationSuccess = false;
				});
		},
	},

	mounted() {
		// check validation token, ask user to log in (to finish registration)
		this.userID = this.$route.params.userID;
		this.validationToken = this.$route.params.validationToken;
		this.checkValidationToken(this.userID, this.validationToken);
	},
};
</script>
